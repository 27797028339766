<template>
  <div class="fixed-action-bar" :style="{'z-index':zIndex}" :class="[isCollapse ? 'mini' : 'full']">
    <div class="content" :style="{'text-align':textAlign,}">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 水平对齐方式
    textAlign: {
      type: String,
      default: "center",
    },
    //层级
    zIndex: {
      type: [String, Number],
      default: 1,
    },
    //需要安全底部的容器id
    safeBottom: {
      type: String,
      default: "main-container",
    },
  },
  computed: {
    //是否收缩侧边栏
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
  },
  created() {
    this.$nextTick(() => {
      this.$main = document.getElementById(this.safeBottom);
      if (this.$main && !this.$main.classList.contains("fixed-mb")) {
        this.$main.classList.add("fixed-mb");
      }
    });
  },
  mounted() {
    document.getElementById("main").appendChild(this.$el);
  },
  beforeDestroy() {
    if (this.$main && this.$main.classList.contains("fixed-mb")) {
      this.$main.classList.remove("fixed-mb");
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
};
</script>
<style lang="scss" scoped>
.fixed-action-bar {
  position: fixed;
  bottom: 0;
  // 88px 是小sidebar 的宽度， $sidebar 是大的
/*  width: calc(100% - #{$sidebar-w} - 88px);*/
  padding: 0 $space;
  transition: width 0.3s ease-in-out;

  width: calc(100% - #{var(--left-w)});

  .content {
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.05);
    border-radius: $radius;

    .el-button {
      & + .el-button {
        margin-left: 20px;
      }
    }
  }
}
</style>

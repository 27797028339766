<template>
  <base-media-dialog :visible="value" :loading="loading" :list="list" :folders="folderOptions" :code="code"
    :type="myType" :multiple="multiple" :max="max" :ratio="ratio" :on-query="onQuery" :on-refresh-code="onRefreshCode"
                     :on-query-free="onQueryFree" :on-uploaded="onUploaded"
    :on-upload="upload" @confirm="confirm" @close="$emit('input', false)">
  </base-media-dialog>
</template>

<script>
import BaseMediaDialog from "../../../base/components/Media/BaseMediaDialog";
import {getDialogList, getFreeList} from "../api/media";
import { scanCode } from "../api/upload";
import filesUpload from "@/base/utils/upload4";

export default {
  // inject: ['mediaKey'],
  components: { BaseMediaDialog },
  props: {
    // v-model
    value: {
      type: Boolean,
      required: true,
    },
    // 多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 最多选几个
    max: {
      type: Number,
      default: 99,
    },
    ratio: [String, Array],
    // 选什么
    type: {
      type: [String, Array],
      default: "image",
    },
    onUploaded: Function,
    // 是否启用加密上传
    encrypt: [Boolean, Number]
  },
  data() {
    return {
      loading: false,
      list: {},
      code: "",
    };
  },
  computed: {
    folderOptions: function () {
      return this.$store.state.media.folderOptions;
    },
    myType: function () {
      if (typeof this.type === "string") {
        return [this.type];
      }
      return this.type;
    },
  },
  methods: {
    onQueryFree(q) {
      return getFreeList(q)
    },
    getList(query) {
      getDialogList(query)
        .then((res) => {
          this.list = res.data;
          this.$store.commit('media/setIsRefresh', false)
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
          this.$store.commit('media/setIsRefresh', false)
        });
    },
    // 列表数据
    onQuery(query) {
      this.loading = true;
      this.list.data = [];
      if (this.folderOptions.length) {
        this.getList(query);
      } else {
        this.$store
          .dispatch("media/getFoldersData")
          .then((res) => {
            this.getList(query);
          })
          .catch((err) => {});
      }
    },
    // 二维码数据
    onRefreshCode() {
      this.loading = true;
      scanCode().then((res) => {
        this.code = res.data;
        this.loading = false;
      });
    },
    // 上传方法
    upload({ files, extra, callback, progress }) {
      filesUpload({
        configApi: "/admin/admin/media/requestUpload",
        data: files,
        pid: extra.pid,
        progress: (num) => {
          progress(num);
        },
      })
        .then((res) => {
          callback(res);
        })
        .catch((err) => {
          console.log(err);
          callback();
        });
    },
    confirm(arr) {
      this.$emit("select", arr);
    },
  },
};
</script>

<style scoped>
</style>

import api from "@/base/utils/request";

// 获取商会精神详情
export const journalDetail = data => {
  return api({
    url: "/admin/cyc/journal/detail",
    method: "post",
    data
  });
};

// 保存本期精神
export const saveDetail = data => {
  return api({
    url: "/admin/cyc/journal/save",
    method: "post",
    data
  });
};
<template>
  <el-upload class="upload-file-turn-text" ref="upload" action="#" :limit="limit" :on-remove="handleRemove"
    :http-request="customUpload" accept=".pdf" :file-list="fileList">
    <el-button :size="buttonSize" type="primary" :loading="loading">
      <i v-if="buttonIcon && !loading" :class="buttonIcon"></i>
      {{loading?'上传中':buttonText}}
    </el-button>
    <template #tip>
      <div v-if="tip" class="el-upload__tip">
        <i v-if="tipIcon" :class="tipIcon"></i>
        {{tip}}
      </div>
    </template>
  </el-upload>
</template>
<script>
import filesUpload from "@/base/utils/upload4";
export default {
  props: {
    //绑定的文件名变量 sync
    textName: {
      type: String,
      required: true,
    },
    //已上传的文件名
    uploadedTextName: String,
    // 已上传的文件路径
    uploadedFileUrl: String,
    //按钮文字
    buttonText: {
      type: String,
      default: "点击上传",
    },
    //按钮大小
    buttonSize: {
      type: String,
      default: "small",
    },
    //按钮图标
    buttonIcon: {
      type: String,
      default: "el-icon-upload2",
    },
    //提示文字
    tip: String,
    //提示文字图标
    tipIcon: String,
  },
  data() {
    return {
      limit: 2, //限制文件数量
      fileList: [], //上传的文件数组，展示用
      fileUrl: "",
      loading: false, //转换中
    };
  },
  methods: {
    //删除文件
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.whetherUpload = false;
      this.$emit("update:textName", "");
    },
    //自定义上传
    customUpload(uploadObj) {
      this.loading = true;
      this.$emit("fileUploading", true);
      const file = uploadObj.file;
      const name = file.name;
      if (this.fileList.length > 0) {
        this.fileList = [];
      }
      filesUpload({
        // 上传配置获取api
        configApi: "/admin/admin/media/requestUpload",
        data: file,
        allowSlice: 1,
        typeArr: ["file"],
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.fileUrl = res.data.url;
          this.$emit("update:fileUrl", res.data.url);
          this.$emit("update:textName", name);
          this.fileList = [{ name }];
          this.loading = false;
          this.$emit("fileUploading", false);
        })
        .catch((err) => {
          this.loading = false;
          this.$emit("fileUploading", false);
        });
    },
    //已有文件
    injectFileList() {
      if (this.uploadedTextName) {
        this.fileList = [{ name: this.uploadedTextName }];
        this.fileUrl = this.uploadedFileUrl;
      }
    },
  },
  created() {
    this.injectFileList();
  },
  watch: {
    uploadedTextName() {
      this.injectFileList();
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-file-turn-text {
  ::v-deep .el-list-enter-active,
  ::v-deep .el-list-leave-active {
    transition: none;
  }

  ::v-deep .el-list-enter,
  ::v-deep .el-list-leave-active {
    opacity: 0;
  }
  .el-upload__tip {
    line-height: 1;
  }
  ::v-deep.el-upload-list__item {
    width: 202px;
  }
}
</style>
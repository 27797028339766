<template>
  <div class="journal-content" v-loading="loading">
    <el-form size="medium" :model="journalForm" :rules="rules" label-position="right" label-width="85px"
      class="small-form" ref="journalForm">
      <el-form-item label="会刊名称：" prop="name">
        <el-input type="text" v-model="journalForm.name" placeholder="请输入会刊名称"></el-input>
      </el-form-item>
      <el-form-item label="会刊描述：" prop="desc">
        <el-input class="word-limit-form" type="text" v-model="journalForm.desc" placeholder="请输入会刊描述"
          maxlength="20" show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="年份：" prop="year">
        <el-date-picker v-model="journalForm.year" type="year" value-format="yyyy" placeholder="请选择年份">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="内容类型：" prop="type">
        <el-radio v-model="journalForm.type" :label="0">上传pdf</el-radio>
        <el-radio v-model="journalForm.type" :label="1">上传图片</el-radio>
      </el-form-item>
      <el-form-item v-if="!journalForm.type" label="上传PDF：" prop="pdf">
        <UploadFileButton :text-name.sync="journalForm.pdf_name" :uploaded-text-name="journalForm.pdf_name"
          :fileUrl.sync="journalForm.pdf" :uploadedFileUrl="journalForm.pdf"
          @fileUploading="(val) => {fileUploading = val}" button-text="请上传pdf文件" />
        <div class="form-tips">提示：请将PDF文件尽量压缩在20M以下</div>
        <el-input v-show="false" v-model="journalForm.pdf"></el-input>
      </el-form-item>
      <el-form-item v-if="journalForm.type" label="上传图片：" prop="images">
        <ImageWall v-model="journalForm.images" draggable :limit="10" :width="92" :height="125">
        </ImageWall>
        <div class="form-tips">建议上传竖版图片，拖拽图片进行排序</div>
        <el-checkbox-group v-show="false" v-model="journalForm.images"></el-checkbox-group>
      </el-form-item>
      <el-form-item label="封面图：" prop="cover">
        <singleImage v-model="journalForm.cover" :width="92" :height="125" ratio="184:250">
          <p slot="info" class="img-tips">建议上传尺寸比例为552*750</p>
        </singleImage>
        <el-input v-show="false" v-model="journalForm.cover"></el-input>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" size="medium" @click="formSubmit('journalForm')"
        :loading="saveLoading || fileUploading">保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { journalDetail, saveDetail } from "../api/add-journal";
import singleImage from "../../common/components/SingleMediaWall.vue";
import ImageWall from "../../common/components/MediaWall.vue";
import UploadFileButton from "../components/UploadFileButton.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  data() {
    return {
      journalId: this.$route.params.id,
      journalForm: {
        id: 0,
        name: "",
        year: "",
        cover: "",
        images: [],
        desc: "",
        type: 0,
        pdf: "",
        pdf_name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入会刊名称", trigger: "blur" }],
        desc: [{ required: true, message: "请输入会刊简介", trigger: "blur" }],
        year: [
          { required: true, message: "请选择会刊年份", trigger: "change" },
        ],
        pdf: [{ required: true, message: "请上传PDF文件", trigger: "change" }],
        cover: [{ required: true, message: "请上传封面图", trigger: "blur" }],
        images: [
          {
            required: true,
            type: "array",
            message: "请上传图片",
            trigger: "change",
          },
        ],
      },
      fileUploading: false,
      loading: false,
      saveLoading: false,
    };
  },
  methods: {
    // 获取商会会刊详情
    getJournalDetail(id) {
      this.loading = true;
      journalDetail({ id })
        .then((res) => {
          const { data } = res;
          this.journalForm = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.saveLoading = true;
          const data = { ...this.journalForm };
          saveDetail(data)
            .then((res) => {
              this.$message.success("保存成功");
              this.timer = setTimeout(() => {
                this.loading = false;
                this.saveLoading = false;
                this.$router.push({ name: "JournalList" });
              }, 1500);
            })
            .catch((err) => {
              this.loading = false;
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: "JournalList" });
    },
  },
  created() {
    if (this.journalId != 0) {
      this.getJournalDetail(this.journalId);
    }
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
  components: { singleImage, ImageWall, UploadFileButton, FixedActionBar },
};
</script>

<style lang="scss" scoped>
.journal-content {
  button {
    & + button {
      margin-left: 20px;
    }
  }

  ::v-deep.el-date-editor.el-input {
    width: 295px;
  }

  .footer-btn {
    padding: 30px 40px 10px;
  }

  .form-tips {
    color: #c0c4cc;
  }

  .img-tips {
    margin-top: 12px;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 15px;
  }
}
</style>

<template>
  <div class="photo-wall" v-loading="uploading">
    <draggable
      :disabled="!draggable"
      animation="500"
      class="wall-content"
      :class="{grid}"
      draggable=".draggable"
      v-model="mediaArr"
    >
      <div
        :key="index"
        :style="{
          width: grid ? '' : widthSize,
          height: grid ? '' : heightSize,
          'border-radius': BorderRadius,
        }"
        class="img-content draggable"
        v-for="(media, index) in mediaArr"
      >
        <div v-if="typeof media === 'string'">
          <!--  :preview-src-list="mediaArr" -->
          <el-image
            :fit="fit"
            :src="fixImageUrl(media)"
            :style="{
              width: grid ? '' : widthSize,
              height: grid ? '' : heightSize,
              'border-radius': BorderRadius,
            }"
            @click="handlePreview(index)"
            class="photo"
          />
          <div
            :style="{ 'line-height': heightSize }"
            class="bg-mask flex-center"
            v-if="showMask && !disabled"
          >
            <p
              @click.stop.prevent="handleCrop(media, index)"
              class="el-icon-scissors"
              v-if="showCut"
            ></p>
            <p
              @click.stop.prevent="handlePreview(index)"
              class="el-icon-zoom-in"
            />
            <p
              @click.stop.prevent="deleteMediaSrc(index)"
              class="el-icon-delete"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="media.type === 'image'">
            <!--
  :preview-src-list="mediaArr.map(m => m.type === 'image' && m.url)"
-->
            <el-image
              :fit="fit"
              :src="fixImageUrl(media.url)"
              :style="{
                width: grid ? '' : widthSize,
                height: grid ? '' : heightSize,
                'border-radius': BorderRadius,
              }"
              @click="handlePreview(index)"
              class="photo"
            />
            <div
              :style="{ 'line-height': heightSize }"
              class="bg-mask flex-center"
              v-if="showMask && !disabled"
            >
              <p
                @click.stop.prevent="handleCrop(media.url, index)"
                class="el-icon-scissors"
                v-if="showCut"
              ></p>
              <p
                @click.stop.prevent="handlePreview(index)"
                class="el-icon-zoom-in"
              />
              <p
                @click.stop.prevent="deleteMediaSrc(index)"
                class="el-icon-delete"
              />
            </div>
          </div>
          <div v-if="media.type === 'video' || media.thumbnail">
            <el-image
              :fit="fit"
              :src="fixImageUrl(media.thumbnail)"
              :style="{
                width: grid ? '' : widthSize,
                height: grid ? '' : heightSize,
                'border-radius': BorderRadius,
              }"
              class="photo"
            />
            <div @click="handlePlay(media)" class="video-mask flex-center">
              <img
                :style="{ 'line-height': heightSize }"
                class="video-play"
                src="@/base/assets/images/media/video-play@2x.png"
              />
            </div>
            <div
              @click.stop.prevent="deleteMediaSrc(index)"
              class="delete"
              v-if="!disabled"
            >
              删除视频
            </div>
          </div>
        </div>
      </div>
      <!-- 添加图片按钮 -->
      <div v-if="(limit && limit - mediaArr.length > 0) && showAdd">
        <div
          :class="{ disabled }"
          :style="{
            width: grid ? '' : widthSize,
            height: grid ? '' : heightSize,
            'border-radius': BorderRadius,
            border: '1px dashed #dcdfe6',
            'margin-bottom': 0,
          }"
          @click="!disabled ? (show = true) : ''"
          class="img-content add"
        >
          <div class="add">
            <div class="add-content">
              <i class="el-icon-plus" />
              <p class="info">{{ addText }}</p>
            </div>
            <input
              @change="onInputFileChange"
              accept="image/*"
              multiple
              type="file"
              v-if="encrypt"
            />
          </div>
        </div>
        <slot name="info" />
      </div>
    </draggable>
    <!-- 图片弹窗 -->
    <media-selector
      :max="limit"
      :ratio="ratio"
      :type="UploadType"
      @select="handlePhoto"
      multiple
      v-if="!encrypt"
      v-model="show"
    >
    </media-selector>
    <!-- 视频弹窗 -->
    <VideoDialog />
    <!-- 裁剪弹窗 -->
    <!--    <crop-dialog :config-api="'/admin/admin/media/requestUpload'" is-dialog />-->
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import VideoDialog from '../../../base/components/Preview/VideoViewer'
import MediaSelector from './MediaSelector'
import { randomString } from '../../../base/utils/tool'
import CropDialog from '../../../base/components/Media/CropDialog'
import filesUpload from '../../../base/utils/upload4'
export default {
  name: 'MediaWall',
  model: {
    prop: 'mediaSrc',
    event: 'handleMediaSrc',
  },
  provide() {
    return {
      mediaKey: this.mediaKey,
    }
  },
  props: {
    //大小 px、%
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    borderRadius: {
      type: [Number, String],
      default: 0,
    },
    UploadType: {
      type: [String, Array],
      default: 'image',
    },
    //多媒体数组
    mediaSrc: {
      type: [Array, Object],
      required: true,
    },
    //图片如何适应容器框
    fit: {
      type: String,
      default: 'cover',
    },
    //添加按钮文字提示
    addText: {
      type: String,
      default: '上传图片',
    },
    //是否显示蒙层
    showMask: {
      type: Boolean,
      default: true,
    },
    // 限制上传数量
    limit: {
      type: Number,
      default: 9,
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否使用拖拽排序
    draggable: {
      type: Boolean,
      default: false,
    },

    ratio: [String, Array],

    showCut: {
      type: Boolean,
      default: false,
    },

    showAdd: {
      type: Boolean,
      default: true,
    },

    // 是否启用加密上传
    encrypt: [Boolean, Number],

    grid: Boolean, // 自适应九宫格
  },
  data() {
    return {
      mediaKey: randomString(),
      show: false, //显示图片弹窗
      currentIndex: 0, //从第几张图片开始预览
      VideoData: {}, // 当前播放视频数据

      uploading: false,
    }
  },
  computed: {
    widthSize() {
      if (typeof this.width === 'number') {
        return this.width + 'px'
      } else {
        return this.width
      }
    },
    heightSize() {
      if (typeof this.height === 'number') {
        return this.height + 'px'
      } else {
        return this.height
      }
    },
    /**
     * @return {string}
     */
    BorderRadius() {
      if (typeof this.borderRadius === 'number') {
        return this.borderRadius + 'px'
      } else {
        return this.borderRadius
      }
    },
    srcList() {
      if (this.mediaSrc.length) {
        if (this.mediaSrc[0].type === 'image' && this.mediaSrc[0].url) {
          return this.mediaSrc.map((item) => item.url)
        }
      }
      return this.mediaSrc
    },
    mediaArr: {
      get() {
        return this.mediaSrc
      },
      set(val) {
        this.$emit('handleMediaSrc', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    onInputFileChange(event) {
      const file = event.target.files
      //修复第二次选择其他文件，但是点击取消依旧触发事件
      if (!file) return

      console.log(file.length, 'input files')

      if (file.length + this.mediaArr.length > 9) {
        this.$message.error('图片数量超过限制：9 张')
        return
      }

      this.uploading = true

      // TODO:
      filesUpload({
        // 上传配置获取api
        configApi: '/admin/admin/media/requestUpload',
        data: file,
        pid: 0,
        encrypt: this.encrypt || 0,
        // 进度回调
        progress: (num, currentProgress) => {
          // '进度：' + percent + '%; 速度：' + speed + 'Mb/s;'
          if (currentProgress) {
            console.log(num, currentProgress)
          }
        },
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.handlePhoto(res.map(r => ({
              ...r,
              type: r.type === 2 ? 'image' : r.type === 3 ? 'video' : r.type
            })))
          } else if (Array.isArray(res.data)) {
            alert('待开发')
          } else this.handlePhoto([res.data])
        })
        .catch((err) => {})
        .finally(() => {
          this.uploading = false
        })
    },
    handleCrop(url) {
      if (typeof this.ratio === 'string') {
        this.ratioList = [this.ratio]
      } else if (this.ratio) {
        this.ratioList = this.ratio
      }
      // this.$store.dispatch("crop/openMediaCropDialog", { imgSrc: url });
      this.$store.dispatch('crop/openMediaCropDialog', {
        imgSrc: [url],
        ratio: this.ratioList[0],
        ratioList: this.ratioList,
        mediaKey: this.mediaKey,
        directly: true,
      })
    },
    //确认
    handlePhoto(mediaList) {
      if (mediaList.length) {
        mediaList.forEach((element) => {
          const isImage = element.type === 'image' || element.type === 2

          if (isImage && typeof this.UploadType !== 'string') {
            /* 裁剪完替换原图 */
            if (element.old_url !== undefined) {
              const oldI = this.mediaArr.findIndex(
                (m) => m.url === element.old_url
              )
              if (oldI !== -1) this.mediaArr.splice(oldI, 1, element)
              else this.mediaArr.push(element)
            } else this.mediaArr.push(element)
          } else if (
            isImage &&
            typeof this.UploadType === 'string'
          ) {
            if (element.old_url !== undefined) {
              const oldI = this.mediaArr.findIndex((m) => m === element.url)
              if (oldI !== -1) this.mediaArr.splice(oldI, 1, element.url)
              else this.mediaArr.push(element.url)
            } else this.mediaArr.push(element.url)
            console.log(typeof this.UploadType)
          } else {
            this.mediaArr.push({ ...element, ...element.config })
          }
        })
      }
      console.log(this.mediaArr, mediaList);
      this.$emit('change')
    },
    //预览图片
    handlePreview(i) {
      this.$previewImage(this.srcList, i)
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit('media/setIsOpenMediaVideoDialog', true)
      this.$store.commit('media/setDialogDetail', data)
    },
    //删除
    deleteMediaSrc(i) {
      this.mediaArr.splice(i, 1)
      this.$emit('change')
    },
  },
  components: {
    CropDialog,
    draggable,
    MediaSelector,
    VideoDialog,
  },
}
</script>
<style lang="scss" scoped>
.photo-wall {
  line-height: 1;
  > .wall-content {
    display: flex;
    flex-wrap: wrap;

    &.grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .img-content {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      margin-right: 16px;
      margin-bottom: 16px;

      &.add {
        aspect-ratio: 1/1;
      }

      &.add.disabled {
        cursor: not-allowed;
      }

      .photo {
        vertical-align: middle;
        background-color: rgb(241, 241, 241);
        aspect-ratio: 1/1;
      }
      .add,
      .bg-mask,
      .video-mask,
      .delete {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
      //添加
      .add {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .add-content {
          font-size: 14px;
          line-height: 1;
          color: rgb(117, 117, 117);
          text-align: center;
          i {
            font-size: 25px;
          }
          .info {
            margin-top: 5px;
            color: inherit;
          }
        }
      }

      input[type='file'] {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
      }

      // 背景蒙层
      .bg-mask {
        height: 100%;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: all 0.3s ease;

        p {
          font-size: 20px;
        }

        p + p {
          margin-left: 15px;
        }
      }
      //删除
      .delete {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        transition: transform 0.2s ease-out;
        transform: translateY(30px);
      }

      .video-mask {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.4);

        .video-play {
          display: inline-block;
          width: 36px;
          height: 36px;
        }
      }

      &:hover:not(.disabled) {
        border-color: #3576ff !important;
        .add {
          .add-content {
            color: #3576ff;
          }
        }
        .bg-mask {
          opacity: 1;
        }
        .delete {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
